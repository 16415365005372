import React, { useEffect } from "react"
import parse from "html-react-parser"
import tw, { styled } from "twin.macro"

const Section = styled.article`
  ${tw`relative px-offset sm:px-12 lg:px-16 xl:px-24 py-10 lg:py-20 z-20 mx-auto`}
  max-width: 945px;
`
const Heading = styled.h1`
  ${tw`relative lg:pt-20 font-ubuntu font-black text-42 md:text-40 lg:text-48 2xl:text-60`}
`

const GutenbergContent = ({ content, title }) => {
  const cookie = content.includes("cookie-settings")
  const cookieSettings = () => {
    window.klaro.show()
  }

  useEffect(() => {
    if (cookie) {
      const cookieElement = document.querySelector("#cookie-settings")
      cookieElement.addEventListener("click", cookieSettings)
      return () => cookieElement.removeEventListener("click", cookieSettings)
    }
  }, [cookie])
  return (
    <Section>
      <Heading>{title}</Heading>
      {parse(content)}
    </Section>
  )
}

export default GutenbergContent
